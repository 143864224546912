import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    USER: localStorage.getItem("USER") ? JSON.parse(localStorage.getItem("USER")) : [],
    TOKEN: localStorage.getItem("TOKEN") ? localStorage.getItem("TOKEN") : "",
    WS:''
  },
  mutations: {
    WS(state,D){
      state.WS =D
    },
    Login (user) {
      localStorage.setItem("USER", JSON.stringify(user));
      localStorage.setItem("TOKEN", "Bearer " + user.token);
    },
    LoginOut () {
      localStorage.setItem("USER", '');
      localStorage.setItem("TOKEN", '');
    },
    updatauser (user) {
      localStorage.setItem("USER", JSON.stringify(user));
    },
  },
  actions: {
  },
  modules: {
  }
})
