<template>
  <div class="footer">
    <div class="footer-box con">
      <div class="footer-info">
        <p v-if="data.web_ipc">{{data.web_ipc.value}}</p>
        <p v-if="data.web_address">{{data.web_address.value}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footers",
  components: {},
  created() {
    this.getdata();
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getdata() {
      this.$api.get({
        url: "index/config",
        data: { type: "is_index" },
        success: (res) => {
          this.data = res.data;
        },
      });
    },
  },
};
</script>