import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss' // global css
import api from "./api/index";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// import * as socket from './api/socket.js'
// Vue.prototype.$SOCKET = socket;

// 请求接口
let $api = new api({})
Vue.prototype.$api = $api
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  next();
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  window.scrollTo(0, 0);
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
