import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const getComponent = (name) => () =>
	import(`../views/${name}.vue`)

  const routes = [
    {
      path: '/',
      name: 'Index',
      component: getComponent("index"),
      meta: {title: '中国法学会-首页'},
    },
    {
      path: '/list',
      name: 'list',
      component: getComponent("list"),
      meta: {title: '中国法学会-列表'},
    },
    {
      path: '/article',
      name: 'Article',
      component: getComponent("article"),
      meta: {title: '中国法学会-文章'},
    },
    {
      path: '/listimg',
      name: 'Listimg',
      component: getComponent("listimg"),
      meta: {title: '中国法学会-列表'},
    }
  ]
  

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
