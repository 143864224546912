<template>
  <div class="header con">
    <div class="logo" @click="goto">
      <img src="https://cdn.d8gx.com/P/logo-zgfxhxczx.png" alt="">
     
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goto() {
      this.$router.push("/");
    },
  },
};
</script>