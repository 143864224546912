<template>
  <div class="nav con">
    <div class="nav-list" v-if="navs">
      <a  v-for="(item,i) in navs" :key="i" :class="i==navs.length-1?'noline':''" @click="goto(item.id)">{{item.title}}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "navs",
  components: {},
  created() {
    this.getNav();
  },
  data() {
    return {
      value: false,
      navs: [],
    };
  },
  methods: {
    goto(e){
      if (e == 7 ) {
        this.$router.push("/listimg?id=" + e);
        return;
      }else{
        this.$router.push("/list?id=" + e);
      }
    },
    getNav() {
      this.$api.get({
        url: "index/navs",
        data:{type:'is_nav'},
        success: (res) => {
          this.navs = res.data;
        },
      });
    },
  },
};
</script>