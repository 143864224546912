import Axios from "axios";
import { Message } from 'element-ui'

let host = 'https://fp.chinalaw.org.cn/api/';

let local = localStorage.getItem("DefaultLanguage") == null ? 'zh-CN' : localStorage.getItem("DefaultLanguage");

Axios.defaults.timeout = 30000;
Axios.interceptors.request.use(
    (config) => {
        config.headers.Lang = local;
        config.cancelToken = localStorage.getItem("TOKEN");
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default class api {
    // 下载文件
    downfile (options) {
        let opt = {
            url: host + options.url,
            method: 'GET',
            responseType: 'blob',
            params: options.data,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }
        Axios.request(opt).then(res => {
            let fileName = res.headers['content-disposition'].replace(/"/g, "");//取消字符串中出现的所有逗号
            fileName = fileName.split('=')[1]
            const BLOB = res.data
            const fileReader = new FileReader()
            if (BLOB) {
                fileReader.readAsDataURL(BLOB)
            }
            fileReader.onload = (event) => {
                let a = document.createElement('a')
                a.download = fileName // 使用默认的文件名
                a.href = event.target.result
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
            }
        })
    }
    get (options) {
        options.method = 'GET'
        options.url = host + options.url
        this.request(options)
    }
    post (options) {
        options.method = 'POST'
        options.url = host + options.url
        this.request(options)
    }
    request (options) {
        let data = {
            url: options.url,
            data: options.data,
            method: options.method,
        };
        if (options.method == 'GET') {
            data['params'] = options.data
        }
        Axios(data)
            .then((res) => {
                if (res.data.code == 401) {
                    //todo 重新登录
                    return;
                }
                if (res.data.code == 200000) {
                    Message.error(res.data.message)
                    return;
                }
                return options.success(res.data)
            })
            .catch((error) => {
                if (error.message.includes('timeout')) {
                    Message.error('请求超时')
                } else if (error && error.response && error.response.status) {
                    let errCode = {
                        400: '错误请求',
                        401: '未授权，请重新登录',
                        403: '拒绝访问',
                        404: '未找到该资源',
                        405: '请求方法未允许',
                        408: '请求超时',
                        500: '服务器端出错',
                        501: '网络未实现',
                        502: '网络错误',
                        503: '服务不可用',
                        504: '网络超时',
                    }
                    Message.error(errCode[error.response.status])
                }
                options.complete(error)
            });
    }
}