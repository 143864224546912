<template>
  <div id="app">
    <Header></Header>
    <Nav></Nav>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/header.vue";
import Nav from "./components/nav.vue";
import Footer from "./components/footer.vue"; 

export default {
  name: "App",
  components: { Header, Nav, Footer },
  created() {
  },

  methods: {
  },
};
</script>